@import "../../../App.scss";

.content_container {
  padding: 18px 0px;
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content_wraper {
    display: flex;
    align-items: center;
    gap: 10px;
    .image {
      border-radius: 56px;
      width: 56px;
      height: 56px;
    }
    .username {
      color: rgb(25, 25, 25);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .description {
      color: rgb(102, 102, 102);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .followbtn {
    padding: 5px 6px;
    font-size: 12px;
    font-weight: 500;
    color: #e84127;
    text-align: center;
    font-style: normal;
    line-height: normal;
    border-radius: 5px;
    border: 1px solid #e84127;
  }
  .actionImage {
    border-radius: 6px;
    width: 56px;
    height: 56px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .content_container {
    .content_wraper {
      .image {
        border-radius: 50px;
        width: 50px;
        height: 50px;
      }
      .username {
        font-size: 16px;
      }
      .description {
        font-size: 14px;
      }
    }

    .actionImage {
      border-radius: 6px;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
}
