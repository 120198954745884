@import "../../../App.scss";
.login_dialog {
  .dialog-title {
    padding: 16px 24px 0px 16px;
  }

  .MuiPaper-elevation {
    border-radius: 20px;
    max-width: 578px;
  }

  .dialog-content {
    .img {
      height: 150px;
      width: 150px;
    }

    .form-heading {
      color: rgb(232, 65, 39);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      span {
        color: rgb(25, 25, 25);
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .form-head-description {
      color: #666;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .form-group {
      margin-bottom: 10px;
      label {
        color: #191919;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .form-control {
        background: transparent !important;
        border-color: #d9d9d9;
        border-radius: 20px !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        height: 60px;
        &:focus {
          box-shadow: none !important;
        }
      }
      input {
        &::placeholder {
          color: #c1bebe !important;
          font-size: 15px;
        }
      }
      .form-area {
        background: transparent !important;
        border-color: #d9d9d9;
        border-radius: 20px !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        width: 100%;
        padding: 10px 15px;
        &:focus-visible {
          outline: 0 !important;
        }
      }
      textarea {
        &::placeholder {
          color: #c1bebe !important;
          font-size: 15px;
        }
      }

      .img_video {
        cursor: pointer;
        .MuiButtonBase-root {
          border: 1px solid #d9d9d9;
          border-radius: 20px !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          padding: 5px;
          display: flex;
          height: 60px;
          gap: 10px;
          background: transparent;
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
          .css-8je8zh-MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }

    form {
      .links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a {
          color: $primaryColor;
          font-size: 14px;
          font-weight: 400;
        }
        .forgot-password {
          color: rgb(102, 102, 102);
          text-align: right;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .theme_btn {
        margin-top: 24px;
        padding: 1rem 2rem;
      }
    }

    .dont-have-account {
      margin: 0;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      text-align: center;
      color: #838383;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0px 24px 20px;
    }
    .text-signup {
      color: rgb(232, 65, 39);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .login_dialog {
    .dialog-content {
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .login_dialog {
    .dialog-content {
      .img {
        height: 100px;
        width: 100px;
      }

      .form-heading {
        font-size: 28px;

        span {
          font-size: 28;
        }
      }
      .form-head-description {
        font-size: 14px;
      }

      .form-group {
        label {
          font-size: 16px;
        }
        .form-control {
          height: 45px;
        }
      }

      form {
        .links {
          .forgot-password {
            font-size: 15px;
          }
        }

        .theme_btn {
          padding: 0.7rem 2rem;
        }
      }

      .dont-have-account {
        font-size: 14px;
      }
      .text-signup {
        font-size: 14px;
      }
    }
  }
}
