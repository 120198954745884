@import "../../../App.scss";

.header_for_about {
  border-bottom: $border;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  .about_title {
    display: flex;
    align-items: center;
    margin: 0px 100px;
    a{
      cursor: pointer;
    }
    span {
      font-weight: 400;
      font-size: 22px;
      line-height: 20px;
    }
  }
}
.main_div {
  padding: 30px;
  margin: 0px 100px;
}

h1 {
  text-align: center;
  color: #5A5A5A;
}
p {
  margin-bottom: 10px;
}
ul {
  margin-left: 20px;
  list-style-type: disc;
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .header_for_about {
    border-bottom: $border !important;
    display: flex !important;
    align-items: center !important;
    padding: 10px 30px !important;
    .about_title {
      display: flex;
      align-items: center;
      padding: 0px !important;
      margin: 10px 0px !important;
      display: block !important;
      a {
        cursor: pointer;
      }
      span {
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
      }
    }
    .logo {
      display: none !important;
    }
  }
  .main_div {
    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $textColor !important;
    }
    padding: 20px !important;
    margin: 0px 6px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header_for_about {
    border-bottom: $border !important;
    display: flex !important;
    align-items: center !important;
    padding: 10px 30px !important;
    .about_title {
      display: flex;
      align-items: center;
      padding: 0px !important;
      margin: 10px 0px !important;
      display: block !important;
      a {
        cursor: pointer;
      }
      span {
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
      }
    }
    .logo {
      display: none !important;
    }
  }
  .main_div {
    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $textColor !important;
    }
    padding: 20px !important;
    margin: 0px 6px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .header_for_about {
    border-bottom: $border !important;
    display: flex !important;
    align-items: center !important;
    padding: 10px 30px !important;
  }
  .main_div {
    .about_title_inside {
      display: flex !important;
      align-items: center !important;
      margin: 0px 6px 20px 0px !important;
      a {
        cursor: pointer !important;
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: $textColor !important;
      }
    }
    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $textColor !important;
    }
    padding: 20px !important;
    margin: 0px 6px !important;
  }
}