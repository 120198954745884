.drawer_container {
  height: 100%;
  width: 600px;
  padding: 32px 15px 0px 32px;

  .header_container {
    display: flex;

    align-items: center;
    padding: 16px 0px;

    .heading {
      display: flex;
      color: #191919;
      text-align: center;
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      justify-content: center;
      width: 100%;
    }
  }

  .component_wrapper {
    height: 100%;
    max-height: calc(100vh - 131px);
    overflow-y: auto;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .main_drawer {
    .MuiPaper-elevation {
      width: 100%;
    }
    .drawer_container {
      width: 100%;
      padding: 15px 0px 0px 15px;
    }
  }

  .component_wrapper {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
  }
}
