.logout_model {
  margin: 0%;

  .close_button {
    position: absolute;
    top: 13px;
    right: 13px;
    color: black;
  }

  .dialog_svg {
    color: #191919;
    text-align: center;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 31.2px */
    padding-top: 22px;
    padding-bottom: 15px;
  }

  .dialog_text {
    color: #191919;
    text-align: center;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 31.2px */
  }

  .dialog_discription {
    padding-top: 4px;
    padding-bottom: 23px;
    color: #666;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    max-width: 306px;
    width: 100%;
  }

  .dialog_content {
    color: #666;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    padding: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dialog_action {
    padding: 0%;
    justify-content: center;
    gap: 8px;
    padding-bottom: 23px;
  }
  .logout_button {
    border-radius: 12px;
    border: 1px solid #ccc;
    background: #fff;
    height: 56px;
    padding: 11px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0%;
    max-width: 171px;
    width: 100%;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .logout_button:hover .logout_text {
    color: #fff;
  }

  .logout_text {
    color: #666;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .stay_button {
    display: flex;
    height: 56px;
    padding: 11px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #e84127;
    margin: 0%;
    max-width: 171px;
    width: 100%;
  }

  .stay_text {
    color: #fff !important;
  }

}

@media screen and (max-width: 425px) and (min-width: 320px) {
   
  .dialog_svg img {
    max-width: 72px !important;
    width: 100% !important;
    height: 72px !important;
  }
  .dialog_text {
    font-size: 22px !important;
  }

  .dialog_discription {
    font-size: 16px !important;
  }

  .logout_text {
    font-size: 16px !important;
  }
}


.all-briefs{
  display: flex;
}