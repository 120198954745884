@import "./assets/custom.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
    &::-webkit-scrollbar{
      background-color: $textLightColor;
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb{
      background: $primaryColor;
      border-radius: 2px;
    }
}
body {
  background-color: #fff !important;
}
img{
  max-width: 100%;
}
a{
  text-decoration: none !important;
}

#root{
  height: 100vh !important;
}

@media (max-width: 1200px) {
  .adsComp{
    padding: 0 !important;
    display: none !important;
   
  }
  
}