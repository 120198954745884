$primaryColor: #e84127;
$textColor: #040406;
$textLightColor: #adaeb3;
$textLightColor1: #787676;
$box-shadow: 0px 4px 40px 0px #0000001a;
$border: 1px solid #353535;
$hashtagColor: #ff7373;
$customeShodow:1px 1px 5px 0px rgba(0, 0, 0, 0.2509803922);

// @import "node_modules/aos/src/sass/aos.scss";
